import axios from '@/axios.js'

export default {
  fetchAllSocialMedia ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/social-media')
        .then(({data: response}) => {
          commit('SET_SOCIAL_MEDIAS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchSocialMedia (context, socialMediaId) {
    return new Promise((resolve, reject) => {
      axios.get(`/social-media/${socialMediaId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeSocialMedia (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/social-media', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateSocialMedia (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/social-media/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroySocialMedia ({ commit }, socialMediaId) {
    return new Promise((resolve, reject) => {
      axios.delete(`social-media/${socialMediaId}`)
        .then((response) => {
          commit('REMOVE_RECORD', socialMediaId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
